import {useState} from 'react'
import PropTypes from 'prop-types'
import {If} from 'babel-plugin-jsx-control-statements'
import mobileThxBackground from '../../../content/media/kot_kontakt-thankyou-m.jpg'
import desktopThxBackground from '../../../content/media/kot_kontakt-thankyou-d.jpg'
import desktopBackground from '../../assets/desktop-background.jpg'
import mobileBackground from '../../assets/mobile-background.jpg'
import Flex from '../../uikit/Flex'
import Text from '../../uikit/Text'
import Background from '../../uikit/Background'
import Form from './Form'
import ThankYouPayment from './ThankYouPayment'


const Payment = ({type, text, gift}) => {
  const [isSubmitted, setSubmitted] = useState(false)

  const handleSubmitted = () => {
    setSubmitted(true)
  }

  if (isSubmitted) {
    return (
      <Background desktopBackground={desktopThxBackground} mobileBackground={mobileThxBackground}>
        <ThankYouPayment />
      </Background>
    )
  }

  return (
    <Background desktopBackground={desktopBackground} mobileBackground={mobileBackground}>
      <Flex column>
        <If condition={text.note}>
          <Text component="p">
            {text.note}
          </Text>
        </If>
        <Text component="h1" textStyle="h1Alternate">
          {text.title}
        </Text>
        <p>
          {text.description}
        </p>
        <Form type={type} gift={gift} onSubmitted={handleSubmitted} />
      </Flex>
    </Background>
  )
}

Payment.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  gift: PropTypes.bool.isRequired,
}

export default Payment
