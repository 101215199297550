import {useCallback, useState} from 'react'


export const FORM_NAME = 'subscription'

const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState)

  const handleChange = useCallback((e) => {
    const {target} = e
    const value = target.type === 'checkbox' ? target.checked : target.value
    const {name} = target

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }))
  }, [])

  return {values, handleChange}
}

export default useForm
