import Appshell from '../../../components/Appshell'
import Payment from '../../../components/screens/Payment'


const text = {
  note: 'Skvelé! Pre osobu, ktorú chcete obdarovať, ste vybrali:',
  title: 'jednorazové predplatné',
  description: `Na jej adresu príde balík štyroch dobrých kníh. Vaša platba bude najviac 70 eur, vrátane
  poštovného (alebo menej, v závislosti od knižného výberu v daný mesiac).`,
}

const Once = () => {
  return (
    <Appshell>
      <Payment gift text={text} type="gift_once" />
    </Appshell>
  )
}

export default Once
