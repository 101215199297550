import {Link} from 'gatsby'
import Button from '../../uikit/Button'
import Flex from '../../uikit/Flex'
import Text from '../../uikit/Text'
import Envelope from '../../assets/icons/envelope.svg'


const ThankYouPayment = () => {
  return (
    <Flex column crossAxis="center" mainAxis="justify" height="fill">
      <Flex column crossAxis="center">
        <Text component="h1" textStyle="h1" align="center">
          <strong>Ďakujem</strong>
          <br />
          za Vaše predplatné!
        </Text>
        <Text component="p" align="center">
          Verím, že môj výber sa vám bude páčiť. V priebehu najbližších dní vám príde potvrdzujúci mail.
        </Text>
        <p>
          Ak máte akékoľvek otázky, neváhajte ma osloviť tu:
        </p>
        <Button component={Link} isLink to="/contact">
          <span className="sr-only">Kontakt</span>
          <Envelope />
        </Button>
        <p>Teším sa na balenie vašich kníh!</p>
      </Flex>
      <Flex>
        <Button component={Link} isLink to="/" onBottom>
          späť na hlavnú stránku
        </Button>
      </Flex>
    </Flex>
  )
}

export default ThankYouPayment
