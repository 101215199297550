import PropTypes from 'prop-types'
import {If, Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import Button from '../../uikit/Button'
import Flex from '../../uikit/Flex'
import Text from '../../uikit/Text'
import useForm, {FORM_NAME} from '../../hooks/useForm'
import TextInput from '../../uikit/form/TextInput'
import Checkbox from '../../uikit/form/Checkbox'
import encode from '../../utils/encode'


const Form = ({type, gift, onSubmitted}) => {
  const {values, handleChange} = useForm()

  const handleSubmit = (e) => {
    e.preventDefault()

    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': FORM_NAME,
        type,
        ...values,
      }),
    })
      .then(() => {
        if (onSubmitted) {
          onSubmitted()
        }
      })
      .catch((error) => console.error(error))
  }

  return (
    <form
        name={FORM_NAME}
        method="post"
        action="/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value={FORM_NAME} />
      <input type="hidden" name="type" value={type} />
      <Flex column>
        <Checkbox
            name="company"
            label="Budete objednávať na firmu?"
            checked={values?.company}
            value={values?.company}
            onChange={handleChange}
        />
        <If condition={values?.company}>
          <Text component="h2">Vaše fakturačné údaje</Text>
          <Flex column crossAxis="stretch" responsive>
            <TextInput
                type="text"
                name="company_name"
                placeholder="Obchodné meno"
                value={values?.company_name}
                onChange={handleChange}
                required
            />
            <TextInput
                type="text"
                name="company_address"
                placeholder="Ulica a číslo"
                value={values?.company_address}
                onChange={handleChange}
                required
            />
            <TextInput
                type="text"
                name="company_zip"
                placeholder="PSČ"
                value={values?.company_zip}
                onChange={handleChange}
                required
            />
            <TextInput
                type="text"
                name="company_city"
                placeholder="Obec"
                value={values?.company_city}
                onChange={handleChange}
                required
            />
            <TextInput
                type="text"
                name="company_ico"
                placeholder="IČO"
                value={values?.company_ico}
                onChange={handleChange}
                required
            />
            <TextInput
                type="text"
                name="company_dic"
                placeholder="DIČ"
                value={values?.company_dic}
                onChange={handleChange}
                required
            />
            <TextInput
                type="text"
                name="company_icdph"
                placeholder="IČ DPH"
                value={values?.company_icdph}
                onChange={handleChange}
            />
          </Flex>
        </If>
        <div>
          <Choose>
            <When condition={gift && values?.company}>
              <Text component="h2">Adresa vašej firmy.</Text>
            </When>
            <When condition={gift && !values?.company}>
              <Text component="h2">Vaše údaje</Text>
            </When>
            <Otherwise>
              <Text component="h2">Dodacia adresa</Text>
            </Otherwise>
          </Choose>
          <Choose>
            <When condition={values?.company && gift}>
              <p>(vyplňte, ak sa odlišuje od fakturačnej)</p>
            </When>
            <When condition={values?.company && !gift}>
              <p>(ak je odlišná od fakturačnej)</p>
            </When>
          </Choose>
        </div>
        <Flex column>
          <TextInput
              type="text"
              name="name"
              placeholder={values?.company ? 'Meno a priezvisko / názov prevádzky' : 'Meno a priezvisko'}
              value={values?.name}
              onChange={handleChange}
              required={!values?.company}
          />
          <TextInput
              type="text"
              name="address"
              placeholder="Ulica a číslo"
              value={values?.address}
              onChange={handleChange}
              required={!values?.company}
          />
          <TextInput
              type="text"
              name="zip"
              placeholder="PSČ"
              value={values?.zip}
              onChange={handleChange}
              required={!values?.company}
          />
          <TextInput
              type="text"
              name="city"
              placeholder="Obec"
              value={values?.city}
              onChange={handleChange}
              required={!values?.company}
          />
        </Flex>

        <Text component="h2">Kontaktné údaje</Text>

        <Flex column>
          <TextInput
              type="text"
              name="phone"
              placeholder="Telefón"
              value={values?.phone}
              onChange={handleChange}
          />
          <TextInput
              type="email"
              name="email"
              placeholder="Email"
              value={values?.email}
              onChange={handleChange}
              required
          />
        </Flex>
        <Checkbox
            name="payment_in_advance"
            label={values?.company
              ? 'Prajete si uhradiť celé predplatné vopred?'
              : `Prajete si uhradiť celé predplatné vopred? (pri platbe vopred je hodnota predplatného znížená
                  na 37, resp. 67 eur. Predplatné je stále možné neskôr zrušiť a nevyčerpaná suma vám bude vrátená
                  na účet)`}
            checked={values?.payment_in_advance}
            value={values?.payment_in_advance}
            onChange={handleChange}
        />
        <If condition={values?.company}>
          <Text component="p">
            *upozornenie: v prípade platby faktúrou a zároveň vopred už predplatné neskôr nie je možné zrušiť.
            Pri platbe vopred je hodnota predplatného znížená na 37, resp. 67 eur.
          </Text>
        </If>
        <Checkbox
            name="terms_and_conditions"
            label={`
                Súhlasím
                <a href="https://drive.google.com/file/d/1FgXV5-ImXghUrcxumx9-p1lNuCSwozfF/view" target="_blank">
                  s podmienkami spracovania osobných údajov
                </a>
              `}
            checked={values?.terms_and_conditions}
            value={values?.terms_and_conditions}
            onChange={handleChange}
            required
        />

        <If condition={gift}>
          <Text component="h2">Zadajte údaje obdarovanej osoby:</Text>
          <Flex column>
            <TextInput
                type="text"
                name="receiver_name"
                placeholder="Meno a priezvisko"
                value={values?.receiver_name}
                onChange={handleChange}
                required
            />
            <TextInput
                type="text"
                name="receiver_address"
                placeholder="Ulica a číslo"
                value={values?.receiver_address}
                onChange={handleChange}
                required
            />
            <TextInput
                type="text"
                name="receiver_zip"
                placeholder="PSČ"
                value={values?.receiver_zip}
                onChange={handleChange}
                required
            />
            <TextInput
                type="text"
                name="receiver_city"
                placeholder="Obec"
                value={values?.receiver_city}
                onChange={handleChange}
                required
            />
            <TextInput
                type="text"
                name="receiver_phone"
                placeholder="Telefón"
                value={values?.receiver_phone}
                onChange={
                  handleChange}
            />
            <TextInput
                type="email"
                name="receiver_email"
                placeholder="Email (nepovinný údaj)"
                value={values?.receiver_email}
                onChange={handleChange}
            />
          </Flex>
          <p>
            To je všetko! Nech má vaša, či váš blízky z dobrých kníh naozajstnú radosť!
          </p>
        </If>

        <Flex column crossAxis="center">
          <p>Hotovo! Môžete</p>
          <Button type="submit">
            ODOSLAŤ
          </Button>
        </Flex>
      </Flex>
    </form>
  )
}

Form.propTypes = {
  type: PropTypes.string.isRequired,
  gift: PropTypes.bool,
  onSubmitted: PropTypes.func,
}

export default Form
