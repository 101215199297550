const encode = (data) => {
  if (typeof window !== undefined) {
    const formData = new window.FormData()

    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }

    return formData
  }
}

export default encode
