import PropTypes from 'prop-types'
import styled from 'styled-components'
import CheckboxIcon from '../../assets/icons/checkbox.svg'
import * as typography from '../theme/typography'


const StyledLabel = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  padding: 1rem 0;
  width: fit-content;
`

const StyledCheckbox = styled.span`
  display: grid;
  place-items: center;
  transition: ${({theme}) => `background ${theme.animation}`}, ${({theme}) => `border ${theme.animation}`};
  border: 1px solid ${({theme}) => `${theme.colors.secondary}`};
  border-radius: ${({theme}) => theme.border.radius.sm};
  background-color: ${({theme}) => theme.colors.text};
  min-width: 1.75rem;
  height: 1.75rem;

  svg {
    opacity: 0;
  }
`

const StyledInput = styled.input`
  appearance: none;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;

  &:checked + ${StyledCheckbox} {
    border: 1px solid ${({theme}) => theme.colors.primary};
    background-color: ${({theme}) => theme.colors.primary};

    svg {
      opacity: 1;
    }
  }
`

const StyledText = styled.span`
  padding-left: 1rem;
  ${typography.label};
`

const Checkbox = ({name, value, label, checked, onChange, required, ...props}) => {
  return (
    <StyledLabel checked={checked} {...props}>
      <StyledInput
          type="checkbox"
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          checked={checked}
      />
      <StyledCheckbox>
        <CheckboxIcon />
      </StyledCheckbox>
      <StyledText dangerouslySetInnerHTML={{__html: label}} />
    </StyledLabel>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  checked: PropTypes.bool,
}

export default Checkbox
