import PropTypes from 'prop-types'
import styled from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import {isEmpty} from 'lodash'
import theme from '../theme/theme'


const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 3.5rem;
`

const StyledInput = styled.input`
  outline: none;
  border: none;
  border-radius: ${theme.border.radius.md};
  padding: 1rem;
  min-height: 100%;
  font-weight: ${({theme}) => theme.fonts.weight.bold};

  &::placeholder {
    padding-top: 0.5rem;
    font-weight: ${({theme}) => theme.fonts.weight.regular};
  }
`

const TextInput = ({name, label, placeholder, value, type, onChange, required, ...props}) => {
  return (
    <StyledContainer>
      <If condition={!isEmpty(label)}>
        <div htmlFor={name}>{label}</div>
      </If>
      <StyledInput
          id={name}
          name={name}
          value={value}
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          {...props}
      />
    </StyledContainer>
  )
}

TextInput.defaultProps = {
  type: 'text',
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
}

export default TextInput
